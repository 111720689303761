<template>
<div>
	<b-jumbotron>
		<b-row>
			<b-col>
				<b-card title="Informazioni server">
					<b-card-text>Sistema operativo: {{`${server.operatingSystem.version} ${server.operatingSystem.architecture}`}}</b-card-text>
					<b-card-text>Uptime Server: {{(server.upTimeServer / 3600).toFixed(2)}} ore</b-card-text>
				</b-card>
			</b-col>
			<b-col>
				<b-card title="Informazioni processo">
					<b-card-text>Versione Node: {{process.nodeVersion}}</b-card-text>
					<b-card-text>Uptime Processo: {{(process.upTime / 3600).toFixed(2)}} ore</b-card-text>
				</b-card>
			</b-col>
			<b-col>
				<b-card title="CPU">
					<b-card-text>Numero di CPU: {{cpus.length}}</b-card-text>
					<b-card-text>Modello: {{cpus[0].model}}</b-card-text>
					<b-card-text>Velocità: {{cpus[0].speed / 1000}} GHz</b-card-text>
				</b-card>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col cols="6">
				<b-card title="Memoria RAM">
					<b-card-body class="text-center">
						<v-chart ref="memoryRAMChart" class="chart" :option="optionsMemoryRAMChart" autoresize></v-chart>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col cols="6">
				<b-card title="Memoria Heap">
					<b-card-body class="text-center">
						<v-chart ref="memoryHeapChart" class="chart" :option="optionsMemoryHeapChart" autoresize></v-chart>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</b-jumbotron>
</div>
</template>

<script>
import * as echarts from 'echarts/core';
import {
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	DataZoomComponent,
	LegendComponent
} from 'echarts/components';
import {BarChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';
import DashboardServices from '../services/DashboardServices';
import VChart, { THEME_KEY } from 'vue-echarts';

echarts.use([
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	DataZoomComponent,
	BarChart,
	CanvasRenderer,
	UniversalTransition,
	LegendComponent
]);

export default {
	name: "DashboardHome",
	components: {
		VChart
	},
	provide: {
		[THEME_KEY]: 'light',
	},
	data() {
		return {
			server: {
				operatingSystem: {
					version: '',
					architecture: '',
					release: '',
					type: '',
					platform: ''
				},
				upTimeServer: 79256
			},
			process: {
				nodeVersion: '',
				upTime: 0
			},
			cpus: [
				{
					model: '',
					speed: 0,
					times: {
						user: 0,
						nice: 0,
						sys: 0,
						idle: 0,
						irq: 0
					}
				}
			],
			optionsMemoryRAMChart: {
				legend: {
					selectedMode: false
				},
				xAxis: {
					type: 'category',
					data: ['Memoria RAM']
				},
				yAxis: {
					type: 'value',
					name: 'Totale RAM',
					min: 0
				},
				series: []
			},
			optionsMemoryHeapChart: {
				legend: {
					selectedMode: false
				},
				xAxis: {
					type: 'category',
					data: ['Memoria Heap']
				},
				yAxis: {
					type: 'value',
					name: 'Totale Heap',
					min: 0
				},
				series: []
			},
			idIntervalGetInfoServer: undefined
		}
	},
	mounted() {
		this.recuperoInformazioniServer();
		this.idIntervalGetInfoServer = setInterval(this.recuperoInformazioniServer, 3000);
	},
	methods: {
		recuperoInformazioniServer() {
			DashboardServices.infoServer(this.$router)
				.then(response => {
					if (response.error)
						return console.log(response.msg);
					this.cpus = response.data.cpus;
					this.server.operatingSystem = response.data.operatingSystem;
					this.server.upTimeServer = response.data.upTimeServer;
					this.process = response.data.process;
					const ramUtilizzataMb = (response.data.ram.totalMemory - response.data.ram.freeMemory) / 1e+6;
					const ramLiberaleMb = response.data.ram.freeMemory / 1e+6;
					const ramTotaleMb = response.data.ram.totalMemory / 1e+6;
					const heapUtilizzatoMb = response.data.process.memory.heapUsed / 1e+6;
					const heapTotaleMb = response.data.process.memory.heapTotal / 1e+6;
					const heapLiberaMb = (response.data.process.memory.heapTotal - response.data.process.memory.heapUsed) / 1e+6;
					this.$refs.memoryRAMChart.setOption({
						yAxis: {
							max: Math.round((ramTotaleMb + Number.EPSILON) * 100) / 100
						},
						series: [
							{
								data: [Math.round((ramUtilizzataMb + Number.EPSILON) * 100) / 100, 0],
								type: 'bar',
								stack: 'a',
								name: `${Math.round((ramUtilizzataMb + Number.EPSILON) * 100) / 100} Mb RAM utilizzata`,
								label: {
									show: false,
									fontSize: 16,
									formatter: '{c} Mb'
								}
							},
							{
								data: [Math.round((ramLiberaleMb + Number.EPSILON) * 100) / 100, 0 ],
								type: 'bar',
								stack: 'a',
								name: `${Math.round((ramLiberaleMb + Number.EPSILON) * 100) / 100} Mb RAM libera`,
								label: {
									show: false,
									fontSize: 16,
									formatter: '{c} Mb'
								}
							}
						]
					});
					this.$refs.memoryHeapChart.setOption({
						yAxis: {
							max: Math.round((heapTotaleMb + Number.EPSILON) * 100) / 100
						},
						series: [
							{
								data: [0, Math.round((heapUtilizzatoMb + Number.EPSILON) * 100) / 100 ],
								type: 'bar',
								stack: 'a',
								name: `${Math.round((heapUtilizzatoMb + Number.EPSILON) * 100) / 100} Mb Heap utilizzata`,
								label: {
									show: false,
									fontSize: 16,
									formatter: '{c} Mb'
								}
							},
							{
								data: [0, Math.round((heapLiberaMb + Number.EPSILON) * 100) / 100 ],
								type: 'bar',
								stack: 'a',
								name: `${Math.round((heapLiberaMb + Number.EPSILON) * 100) / 100} Mb Heap libera`,
								label: {
									show: false,
									fontSize: 16,
									formatter: '{c} Mb'
								}
							}
						]
					});
				})
				.catch();
		}
	},
	destroyed() {
		clearInterval(this.idIntervalGetInfoServer);
	}
}
</script>

<style scoped>
.chart {
	position: relative;
	height: 50vh;
	overflow: hidden;
}
</style>
