import axios from 'axios';

const headers = {
	'Content-Type': 'application/json',
	'Accept-Version': '1.0.0'
}

export default class DashboardServices {
	static getClientsName(router) {
		return new Promise((resolve, reject) => {
			axios.get('api/dashboard/client', {
				headers: {
					...headers,
					authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
				}
			})
				.then(value => {
					resolve(value.data);
				})
				.catch(reason => {
					if (reason.response.status === 401) {
						router.push({name: 'Login'})
							.then()
							.catch();
					}
					reject(reason);
				});
		});
	}
	static getServiziAnagrafiche(router) {
		return new Promise((resolve, reject) => {
			axios.get('api/dashboard/servizianagrafiche', {
				headers: {
					...headers,
					authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
				}
			})
				.then(value => {
					resolve(value.data);
				})
				.catch(reason => {
					if (reason.response.status === 401) {
						router.push({name: 'Login'})
							.then()
							.catch();
					}
					reject(reason);
				});
		});
	}

	static infoServer(router) {
		return new Promise((resolve, reject) => {
			axios.get('api/dashboard/info/server', {
				headers: {
					...headers,
					authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
				}
			})
				.then(value => {
					resolve(value.data);
				})
				.catch(reason => {
					if (reason.response.status === 401) {
						router.push({name: 'Login'})
							.then()
							.catch();
					}
					reject(reason);
				});
		});
	}

	static getLogs(router, offset = 0, limit = 20, filter = undefined) {
		return new Promise((resolve, reject) => {
			axios.get('api/dashboard/log', {
				headers: {
					...headers,
					authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
				},
				params: {
					offset,
					limit,
					...filter
				}
			})
				.then(value => {
					resolve(value.data);
				})
				.catch(reason => {
					if (reason.response.status === 401) {
						router.push({name: 'Login'})
							.then()
							.catch();
					}
					reject(reason);
				});
		});
	}

	static getClients(router) {
		return new Promise((resolve, reject) => {
			axios.get('api/dashboard/client', {
				headers: {
					...headers,
					authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
				}
			})
				.then(value => {
					resolve(value.data);
				})
				.catch(reason => {
					if (reason.response.status === 401) {
						router.push({name: 'Login'})
							.then()
							.catch();
					}
					reject(reason);
				});
		});
	}

	static createClient(router, data) {
		return new Promise((resolve, reject) => {
			axios.post('api/dashboard/client', data, {
				headers: {
					...headers,
					authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
				}
			})
				.then(value => {
					resolve(value.data);
				})
				.catch(reason => {
					if (reason.response.status === 401) {
						router.push({name: 'Login'})
							.then()
							.catch();
					}
					reject(reason);
				});
		});
	}

	static deleteClient(router, id) {
		return new Promise((resolve, reject) => {
			axios.delete(`api/dashboard/client/${id}`, {
				headers: {
					...headers,
					authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
				}
			})
				.then(value => {
					resolve(value);
				})
				.catch(reason => {
					if (reason.response.status === 401) {
						router.push({name: 'Login'})
							.then()
							.catch();
					}
					reject(reason);
				});
		});
	}

	static updateClient(router, id, data) {
		return new Promise((resolve, reject) => {
			axios.put(`api/dashboard/client/${id}`, data, {
				headers: {
					...headers,
					authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
				}
			})
				.then(value => {
					resolve(value.data);
				})
				.catch(reason => {
					if (reason.response.status === 401) {
						router.push({name: 'Login'})
							.then()
							.catch();
					}
					reject(reason);
				});
		});
	}
}
